import { useEffect, useState } from 'react'
import { fetchGraphQlApi } from '@/clientRequests/api'
import { getRecentlyViewed } from '@/utils/recentlyViewed'
import { GET_PRODUCT_DATA_BY_TAGS_QUERY } from '@/services/content/queries'
import { GET_CATALOG_PRODUCTS_QUERY } from '@/services/catalog/queries'
import constants from '@/temp-configs/category-constants'
import { getPrismicRef } from '@/utils/prismicPreview'

export async function getRelatedProducts(products) {
    const productCodeSet = new Set(products.map(product => product.productCode))
    const relatedProductCodeSet = new Set()

    products.forEach((product) => {
        if (!product.relationships || product.category.toLowerCase() != constants.BUNDLES) return

        product.relationships.forEach((relationship) => {
            relationship.relatedProductCodes.forEach((productCode) => {
                // Do not fetch products that are already in the product list
                if (!productCodeSet.has(productCode)) {
                    relatedProductCodeSet.add(productCode)
                }
            })
        })
    })

    const relatedProductCodeArray = [...relatedProductCodeSet]
    let relatedProducts = []

    if (relatedProductCodeArray.length > 0) {
        relatedProducts = await fetchGraphQlApi('/catalog', GET_CATALOG_PRODUCTS_QUERY, { productCodes: relatedProductCodeArray })
    }

    const productsWithBundles = products.map(product => {
        const revisedProduct = Object.assign({}, product)
        revisedProduct.bundledProducts = []
        revisedProduct.relationships.forEach((relationship) => {
            if (relationship.relationshipLabel === 'bundledProducts') {
                relationship.relatedProductCodes.forEach((productCode) => {
                    const bundleProduct = relatedProducts.products.find(related => related.productCode === productCode) || products.find(related => related.productCode === productCode)
                    revisedProduct.bundledProducts.push(bundleProduct)
                })
            }
        })
        return revisedProduct
    })

    return productsWithBundles
}

export default function useFetchRecentlyViewedData(productCodes) {
    const [recentlyViewedData, setRecentlyViewedData] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const prismicRef = getPrismicRef()
        const recentlyViewedProducts = productCodes || getRecentlyViewed()
        const fetchRecentlyViewedData = async () => {
            try {
                if (recentlyViewedProducts.length > 0) {
                    let [contentData, catalogData] = await Promise.all([
                        fetchGraphQlApi('/content', GET_PRODUCT_DATA_BY_TAGS_QUERY, { tags: recentlyViewedProducts }, prismicRef),
                        fetchGraphQlApi('/catalog', GET_CATALOG_PRODUCTS_QUERY, { productCodes: recentlyViewedProducts }, prismicRef)
                    ])

                    const relatedCatalogData = await getRelatedProducts(catalogData.products)

                    const mergedData = relatedCatalogData.map(catalogProduct => {
                        const contentProduct = contentData.getProductDataByTags.find(contentProduct => contentProduct.sku === catalogProduct.productCode)
                        return {
                            ...catalogProduct,
                            ...contentProduct
                        }
                    })

                    setRecentlyViewedData(mergedData)
                }
            } catch (error) {
                console.error(`Error fetching recently viewed data: ${error.message}`)
            } finally {
                setLoading(false)
            }
        }
        fetchRecentlyViewedData()
    }, [productCodes])

    return { recentlyViewedData, loading }
}