import { storageWrapper } from '@saatva-bits/pattern-library.utils.storage'
import logger from '@saatva-bits/pattern-library.utils.logger'

import productCodes from '@/temp-configs/product-codes'

const storage = storageWrapper()

const RECENTLY_VIEWED_KEY = 'recentlyViewed'
const STORAGE_TYPE = 'local'

function validateCode(productCode) {
    return Object.values(productCodes).includes(productCode)
}

export const getRecentlyViewed = () => {
    try {
        let recentlyViewed = storage.getItem(RECENTLY_VIEWED_KEY, STORAGE_TYPE) || []

        // remove any expired entries (older than 30 days)
        // Remove any entries with erroneous product codes
        const thirtyDaysAgo = new Date().getTime() - 30 * 24 * 60 * 60 * 1000
        recentlyViewed = recentlyViewed.filter(viewedEntry => {
            const notExpired = viewedEntry.timestamp > thirtyDaysAgo
            const validCode = validateCode(viewedEntry.productCode)
            return notExpired && validCode
        })

        // return the product codes
        return recentlyViewed.map(viewedEntry => viewedEntry.productCode)
    } catch (error) {
        // this should only happen if the storage is corrupted
        logger.error({
            message: 'Error getting recently viewed',
            location: 'utils/recentlyViewed.js',
            error
        })

        // return an empty array if there is an error
        return []
    }
}
