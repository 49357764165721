import orderBy from 'lodash/orderBy'
import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { formatProduct } from './product'

export const FILTER_PROPERTIES = {
    sizes: 'sizes',
    colors: 'colors',
    materials: 'materials'
}

/**
 * Parse the query params and map them down to the expected filter properties
*/
export const retrieveParams = (params, sorters, filters, defaultSort, defaultFilters) => {
    const initialFilters = {}

    filters.forEach(({ id, label, options }) => {
        const filterParams = params[id] || defaultFilters[id]
        const filterValues = filterParams?.split(',') || []

        initialFilters[id] = {
            label,
            property: id,
            labels: {},
            values: []
        }

        options.forEach(({ id: optionId, label }) => {
            initialFilters[id].labels[optionId] = label
            if (filterValues.includes(optionId)) {
                initialFilters[id].values.push(optionId)
            }
        })
    })

    const initialSort = {}
    const sorting = params.sorting || defaultSort
    const sort = sorters.find(({ value }) => sorting === value)

    if (sort) {
        initialSort.sortByLabel = sort.label
        initialSort.sortBy = {
            term: sort.term,
            order: sort.order
        }
    } else {
        const defaultInitialSort = sorters.find(({ value }) => defaultSort === value)

        initialSort.sortByLabel = defaultInitialSort?.label || 'Featured'
        initialSort.sortBy = {
            term: defaultInitialSort?.term || 'featured',
            order: defaultInitialSort?.order || ''
        }
    }

    return {
        sort: initialSort,
        filters: initialFilters
    }
}

/**
 * Iterates over each filter object and pushes the values that they all have
 * with the related key that defines which specific filter that value comes from
 * @param {*} filtersObj
 * @returns
*/
export const getSelectedValues = (filtersObj) => {
    const foundFilters = []

    for (const key in filtersObj) {
        const { values, labels } = filtersObj[key]
        if (values.length) {
            values.forEach(value => {
                const label = labels?.[value]
                foundFilters.push({ key, label, value: value })
            })
        }
    }

    return foundFilters
}

const getMetaDataByKey = (metaData, keyValue = 'default') => metaData.find(({ key }) => keyValue === key)

/**
 * Will return the meta data based on filter value if there is a single filter selected
 * after 2 or more it will return the default
 * @param {*} selectedValues
 * @param {*} metaData
 * @returns
 */
export const getFilterMetaData = (selectedValues, metaData) => {
    if (isEmpty(metaData)) {
        return { pageheader: 'Smarter Luxury Sleep' }
    }

    if (selectedValues.length === 1) {
        return getMetaDataByKey(metaData, selectedValues[0].value) || getMetaDataByKey(metaData) || {}
    }

    return getMetaDataByKey(metaData) || {}
}

/**
 * Iterates over the selected user values for the specified filter and maps them down to
 * a key value object for handling those selections.
 *
 * @param {'sizes' | 'colors' | 'materials'} filterProperty
 * @param {Array<{ key: string, value: string, label: string }>} selectedValues
 * @returns { Array<{ key: string, value: string, label: string }> }
 */
export const getValuesByProperty = (filterProperty, selectedValues) => selectedValues.filter(({ key }) => filterProperty === key)

/**
 * Util to abstract the logic for the sorting, leverages orderBy from lodash.
*/
export const applySort = (itemList, sortBy) => {
    if (sortBy?.term && sortBy?.order) {
        const term = camelCase(sortBy.term)
        const order = camelCase(sortBy.order)
        return orderBy(itemList, [term], [order])
    }
    return itemList
}

/**
 * Parses the filter config from our services and maps it down for app use
 * @param {object} filters
 * @returns {{ label: string; property: string; attributePaths: string[], options: { value: string; label: string; matchingValues: string[] }[] }}
 */
export const getFilterOptions = (filters) => (
    filters.map(({ id, label, attributePaths, options, hidden }) => {
        return {
            label,
            property: id,
            attributePaths,
            hidden,
            options: options.map(option => ({ value: option.id, label: option.label, matchingValues: option.matchingValues }))
        }
    })
)

/**
 * Merges filters and sorters with prismic and catalog formatted product data
 *
 * @param {object} props
 * @property {object} catalogProduct
 * @property {object} contentProduct
 * @property {object} filters
 * @property {array} sorters
 * @property {object[]} filterConfig
 * @returns
 */
export const formatFilterProduct = ({
    contentProduct,
    catalogProduct,
    sorters,
    filtersConfig
}) => {
    const productSorters = {}

    sorters.forEach(({ term }) => {
        const key = camelCase(term)
        const value = contentProduct[key]

        if (value) {
            productSorters[key] = value
        }
    })

    return {
        ...formatProduct(contentProduct, catalogProduct, filtersConfig),
        ...productSorters
    }
}

/**
 *  @typedef {Object} SelectedFilter
 * @property {string[]} attributePaths - The paths to the attributes to check in the variant object.
 * @property {string[]} matchingValues - An array of values that match this option.
 */

/**
 * @typedef {Object} AttributeOption
 * @property {string} label - The display label for the option.
 * @property {string} id - The id for the option. Used to match query param values.
 * @property {string[]} matchingValues - An array of values that match this option.
 *
 * @typedef {Object} ProductAttribute
 * @property {string} label - The display label for the attribute.
 * @property {string} id - The id for the attribute. Used to match query param keys.
 * @property {string[]} attributePaths - The paths to the attributes to check in the variant object.
 * @property {AttributeOption[]} options - An array of options for this attribute.
 *
 * @param {ProductAttribute[]} filterConfig - An array of product attributes.
 *
 * @typedef {Object} QueryParamValues
 * @property {string} property - The query param key. Should match a ProductAttribute id.
 * @property {string[]} values - List of attribute values. Should match AttributeOption ids.
 *
 * @param {QueryParamValues[]} queryParamValues - An array of query params.
 *
 * @returns {SelectedFilter[]} An array of filters to compare to each variant
 */
export const getSelectedFilters = (filterConfig, queryParamValues) => {
    const selectedFilters = []

    filterConfig.forEach((attribute) => {
        // find the query param property that matchs the filter
        const matchingQueryParam = queryParamValues.find((queryParamValue) => queryParamValue.property == attribute.property)

        if (matchingQueryParam) {
            const allMatchingValues = []
            
            matchingQueryParam.values.forEach((queryParamValue) => {
                const matchingOption = attribute.options.find(option => option.value === queryParamValue)
                if (matchingOption) {
                    allMatchingValues.push(...matchingOption.matchingValues)
                }
            })

            selectedFilters.push({
                property: attribute.property,
                matchingValues: allMatchingValues,
                attributePaths: attribute.attributePaths
            })
        }
    })
    return selectedFilters
}

/**
 * Retrieves the attribute value according to an attribute path and returns it in the expected type
 * @param {Object} variant -
 * @param {SelectedFilter} filter - An array of filters to compare to each variant
 */
export const getVariantAttributeValues = (variant, filter) => {
    let attributeValue = null

    for (const attributePath of filter.attributePaths) {
        let aux = get(variant, attributePath)
        if (aux) {
            attributeValue = aux
            break
        }
    }

    // example of an edge case, attributeValue is an array so its true but the value inside is bad
    // So lets just filter out bad data before returning
    // anais-chair 245S-CH300101 [ null ]
    if (Array.isArray(attributeValue)) {
        return attributeValue.filter(value => Boolean(value))
    } else if (typeof attributeValue === 'string' || typeof attributeValue === 'boolean') {
        return [attributeValue]
    } else {
        return []
    }
}

/**
 * @param {Object} variant - Variant data
 * @param {SelectedFilter} filter
 */
const isVariantMatch = (variant, filter) => {
    if (filter.matchingValues && filter.matchingValues.length === 0) {
        return true
    }

    const attributeValues = getVariantAttributeValues(variant, filter)
    return attributeValues.some((value) => {
        // The values arent consistent so we lowercase everything before comparing
        const loweredMatchValues = filter.matchingValues.map(value => value.toString().toLowerCase())

        return loweredMatchValues.includes(value.toString().toLowerCase())
    })
}

/**
 *
 * @typedef {Object} Variant
 * @property {string} price - variant price.
 *
 * @typedef {Object} Product
 * @property {Variant[]} variants - List of variants for a product
 *
 * @param {Product[]} products - An array of query params.
 * @param {SelectedFilter[]} filters - An array of filters to compare to each variant
 */
export const applyVariantFiltering = (products, filters, getDiscounts) => {
    const filteredProducts = []

    products.forEach((product) => {
        const matchingPrices = []
        const matchingDiscountedPrices = []
        const matchingDiscountLabels = []

        let productIsMatch = false
        const matchingVariants = []
        product.variants.forEach((variant) => {
            // check if this variant passes every filter
            const variantIsMatch = filters.every((filter) => {
                return isVariantMatch(variant, filter)
            })

            let finalPrice = variant.price

            // if this variant is a match record its price and discounted price
            if (variantIsMatch && variant.price) {
                matchingPrices.push(variant.price)

                if (getDiscounts) {
                    let getDiscountsProducts = [{
                        sku: variant.sku,
                        parentSku: variant.productCode,
                        quantity: 1,
                        category: variant.category,
                        price: variant.price
                    }]

                    if (variant.bundledVariants && variant.bundledVariants.length) {
                        getDiscountsProducts = variant.bundledVariants.map((partialBundledVariant) => {
                            const fullProduct = product.bundledProducts.find(product => product.productCode === partialBundledVariant.productCode)
                            const fullVariant = fullProduct && fullProduct.variants.find(variant => variant.sku === partialBundledVariant.sku)

                            return fullProduct && {
                                sku: partialBundledVariant.sku,
                                parentSku: partialBundledVariant.productCode,
                                quantity: partialBundledVariant.quantity,
                                category: fullVariant.category,
                                price: fullVariant.price
                            }
                        }).filter(Boolean)
                    }

                    const { finalPrice: discountedPrice, labelBreakdown } = getDiscounts(getDiscountsProducts)
                    finalPrice = discountedPrice
                    matchingDiscountedPrices.push(discountedPrice)

                    // if the label is not already in the array, add it
                    labelBreakdown?.forEach((discount) => {
                        if (!matchingDiscountLabels.some((matchingDiscount) => matchingDiscount.label === discount.label)) {
                            matchingDiscountLabels.push({
                                label: discount.label,
                                discountType: discount.discountType,
                            })
                        }
                    })
                }
            }

            // if this variant is a match, this its parent is a match
            if (variantIsMatch) {
                productIsMatch = true
                matchingVariants.push({
                    ...variant,
                    finalPrice
                })
            }
        })

        if (productIsMatch) {
            filteredProducts.push({
                ...product,
                matchingVariants: matchingVariants,
                lowestPrice: Math.min(...matchingPrices),
                highestPrice: Math.max(...matchingPrices),
                lowerPrice: Math.min(...matchingDiscountedPrices) || Math.min(...matchingPrices),
                upperPrice: Math.max(...matchingDiscountedPrices) || Math.max(...matchingPrices),
                discountLabels: matchingDiscountLabels
            })
        }
    })

    return filteredProducts
}
