import DetailProductTileSlider from '@/components/DetailProductTileSlider'

const RecentViewedProductSlider = ({ dataSelector, recentlyViewedData, title = 'Recently Viewed', sectionClassname }) => {
    const { products, loading } = recentlyViewedData
    const productsLength = products.length

    return (
        <DetailProductTileSlider
            products={products}
            sectionClassName={sectionClassname}
            title={title}
            dataSelector={dataSelector}
            simpleSliderProps={{
                shownSize: productsLength > 3 ? 3 : productsLength,
                infinite: false,
                pageDotsAndNumbers: false,
                pageDots: productsLength < 2 ? false : true
            }}
            isLoading={loading}
            hideSwatches={true}
            hideSliderSideButtons={productsLength === 1}
        />
    )
}

export default RecentViewedProductSlider